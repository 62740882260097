import * as React from "react";

import { WebContent as CmsApiWebContent, WithId } from "@maxxton/cms-api";
import { getNoDataFoundContent, getNoDataFoundTemplate, setOpacityOnHide } from "../../../components/utils";

import { AccommodationType } from "../../mxts";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Icon } from "../../../components/Icon";
import { WidgetOptions } from "./";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isEmpty } from "lodash";
import { renderNoResultsFoundContent } from "../../dynamic/containerWidget.util";

interface ReservableUnitWidgetProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
}

export const ReservableUnit = ({ accommodationType, options, context, context: { currentLocale, site } }: ReservableUnitWidgetProps) => {
    const [noResultFoundTemplate, setnoResultFoundTemplate] = React.useState<JSX.Element[]>();
    const [noResultFoundWebContent, setnoResultFoundWebContent] = React.useState<CmsApiWebContent & WithId>();
    const hideWidget = setOpacityOnHide(options);

    React.useEffect(() => {
        setNoResultFoundContent();
    }, []);

    const setNoResultFoundContent = async () => {
        if (options.externalSupplierBehavior && !options.hideReservableWidget) {
            if (options.templateId) {
                const template = await getNoDataFoundTemplate(options.templateId, context);
                if (template) {
                    setnoResultFoundTemplate(template);
                }
            }
            if (options.webContentId) {
                const webContent = await getNoDataFoundContent(options.webContentId);
                if (webContent) {
                    setnoResultFoundWebContent(webContent);
                }
            }
        }
    };

    const renderRoot = () => {
        const localContent = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
        const filterRegExp = new RegExp("\\{reservable_unit_count}");
        if (accommodationType) {
            if (options.externalSupplierBehavior && accommodationType.supplierOrigin) {
                if (options.hideReservableWidget) {
                    return null;
                }
                if (!isEmpty(noResultFoundWebContent) || !isEmpty(noResultFoundTemplate)) {
                    return (
                        <div className={`${hideWidget}`}>
                            {renderNoResultsFoundContent({ noResultsFoundWebContent: noResultFoundWebContent, noResultsFoundTemplate: noResultFoundTemplate, context })}
                        </div>
                    );
                }
            }
            return accommodationType.reservableUnits < (options.maxReservableUnitCount || 5) ? (
                <div
                    className={`font-error font-size-70 reservable-unit${
                        options.descFontColor && options.descFontColor.indexOf("theme") > -1
                            ? `color-${options.descFontColor}`
                            : options.descFontColor && options.descFontColor.indexOf("rgba") === -1
                            ? options.descFontColor
                            : ""
                    } ${hideWidget}`}
                    style={{
                        color: options.descFontColor && options.descFontColor.indexOf("rgba") > -1 ? options.descFontColor : undefined,
                    }}
                >
                    {!options.iconConfiguration?.iconRight && <Icon iconConfiguration={options.iconConfiguration} />}
                    {accommodationType.reservableUnits === 1 ? (
                        <span>{localContent?.singleReservableUnit.replace(filterRegExp, accommodationType.reservableUnits.toString())}</span>
                    ) : (
                        <span>{localContent?.availableReservableUnit.replace(filterRegExp, accommodationType.reservableUnits.toString())}</span>
                    )}
                    {options.iconConfiguration?.iconRight && <Icon iconConfiguration={options.iconConfiguration} />}
                </div>
            ) : null;
        }
        return null;
    };

    return renderRoot();
};
