import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { IconConfigurationOptions, IconConfigurationSpec, defaultIconConfigurationValues } from "../../../utils/icon.util";

import { ReservableUnit } from "./ReservableUnit";
import { SharedWidgetParams } from "../../widget";
import { TypesearchContainerWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions {
    maxReservableUnitCount: number;
    styleIds?: string[];
    localized: LocalizedOptions[];
    externalSupplierBehavior?: boolean;
    hideReservableWidget?: boolean;
    webContentId?: string | null;
    templateId?: string | null;
    descFontColor: string;
    iconConfiguration: IconConfigurationOptions;
}

export interface LocalizedOptions {
    locale: string;
    availableReservableUnit: string;
    singleReservableUnit: string;
}

const TARGETS = ["reservable-unit"];

interface WidgetOptionsFormParams {
    widgetOptionsFormId: string;
}

// eslint-disable-next-line max-lines-per-function
const widgetOptionsForm = ({ widgetOptionsFormId }: WidgetOptionsFormParams): FormSpec<WidgetOptions> => ({
    id: widgetOptionsFormId,
    name: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "reservableUnit-widget-option"),
    pluralName: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "reservableUnit-widget-options"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    properties: [
                        [
                            {
                                variable: "maxReservableUnitCount",
                                label: getI18nLocaleObject(namespaceList.widgetReservableUnit, "maxReservableUnitCount"),
                                type: "number",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetReservableUnit, "reservableUnitDescription"),
                                        type: "paragraph",
                                    },
                                    {
                                        variable: "singleReservableUnit",
                                        label: getI18nLocaleObject(namespaceList.widgetReservableUnit, "singleReservableUnit"),
                                        type: "text",
                                        required: true,
                                    },
                                    {
                                        variable: "availableReservableUnit",
                                        label: getI18nLocaleObject(namespaceList.widgetReservableUnit, "availableReservableUnit"),
                                        type: "text",
                                        required: true,
                                    },
                                ],
                            }),
                            {
                                variable: "externalSupplierBehavior",
                                label: getI18nLocaleObject(namespaceList.widgetReservableUnit, "externalSupplierBehavior"),
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetReservableUnit, "externalSupplierDescription"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.externalSupplierBehavior,
                            },
                            {
                                variable: "hideReservableWidget",
                                label: getI18nLocaleObject(namespaceList.admin, "hideWidget"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.externalSupplierBehavior,
                            },
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundWebcontent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                                visible: (options: WidgetOptions) => !!options.externalSupplierBehavior && !options.hideReservableWidget,
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                                visible: (options: WidgetOptions) => !!options.externalSupplierBehavior && !options.hideReservableWidget,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            IconConfigurationSpec<WidgetOptions, keyof WidgetOptions>({ variable: "iconConfiguration" }),
                            {
                                variable: "descFontColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontColor"),
                                type: "dual-color",
                                default: "font-default",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
});

export const reservableUnitWidget = ({ widgetId, widgetType, widgetOptionsFormId }: SharedWidgetParams): TypesearchContainerWidgetSpec<WidgetOptions> => ({
    id: widgetId,
    type: widgetType,
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "reservableUnitWidget"),
    description: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "reservableUnitDescription"),
    optionsForm: widgetOptionsForm({ widgetOptionsFormId }),
    defaultOptions: (): WidgetOptions => ({
        maxReservableUnitCount: 5,
        styleIds: [],
        localized: [],
        descFontColor: "font-default",
        iconConfiguration: defaultIconConfigurationValues,
    }),
    async render(widget, context) {
        return <ReservableUnit options={widget.options} context={context} />;
    },
    container: false,
});
